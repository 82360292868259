import React, { useState, useEffect } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  Snackbar,
  FormControl,
  InputLabel,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Input,
  InputAdornment,
  TableBody,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import { ArrowBack, Delete, Edit, Group, Send } from "@mui/icons-material";
import {
  LocalUpdate,
  postCall,
  getCall,
  profile,
  taxes,
  today,
  usd,
  FormattingNumber,
} from "../../Helper/Helper";
import ModelPopup from "../../components/ModelPopup";
import SubNavBar from "../../components/SubNavBar";
import SelectPerson from "../../components/Select/SelectPerson";

import PDFUpload from "../../components/PDFUpload";
import ItemAddComponenet from "../../components/ItemAddComponenet";
import SearchItemVendorPopup from "../../components/SearchItemVendorPopup";
import SearchResultPopup from "../../components/SearchResultPopup";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function AddRFQ({ navigate }) {
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const [message, setmessage] = useState("");
  const [disablebtn, setDisablebtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [rfqData, setRfqData] = useState(true);
  const EmptyItem = {
    item_title: "",
    item_code: "",
    status: 1,
    item_qty: 1,
    qty: 1,
    price: 0,
    cost: 0,
    item_cost: 0,
    weight: 0,
    currency: "Rs.",
    unit_of_measure: "",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    deliveryStatus: "",
    channel_id: profile[0].id,
    id: 0,
  };
  const [formData, setFormData] = useState({
    SelectedItems: [EmptyItem],
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    PersonList: [],
    DeletedItems: [],
    Channel: {},
    prStatus: "Active",
    item_count_total: 0,
    total_items_qty: 0,
    items_left: 0,
    channel_id: profile[0].id,
    created_by: ActiveUser[0].username,
    user_id: ActiveUser[0].id,
    role: ActiveUser[0].role,
    username: ActiveUser[0].username,
    name: ActiveUser[0].name,
    atten_to: 0,
    pr: "",
    remarks: "",
    depart: "",
    submitDate: today,
    recevieDate: today,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Delete Item
  const DeleteItem = (data, dindex) => {
    ValidationMessage("Item row deleted", "error");
    setFormData({
      ...formData,
      DeletedItems: [
        ...formData.DeletedItems,
        formData.SelectedItems.filter((item, index) => {
          if (index === dindex) {
            return item;
          }
        }),
      ],
      SelectedItems: formData.SelectedItems.filter((item, index) => {
        return index !== dindex;
      }),
    });
  };

  // Insert RFQ
  const InsertRFQ = async (data) => {
    // console.log("submitted", data);
    await postCall("rfq/create", data)
      .then((response) => {
        console.log("check", response.data);
        if (response.data === "PR Already exist") {
          ValidationMessage("RFQ Already exsist", "error");
        } else {
          ValidationMessage("RFQ Successfully Created..", "success");
          navigate("/admin/rfq/view/" + response.data.insertId, {
            state: {
                message: "RFQ/ PR has been sucessfully created",
              },
          })
        }
      })
      .catch(function (err) {
        ValidationMessage(err.message, "error");
      });
  };

  // Add Selected Item From Popup search menu
  const AddSelected = (data = EmptyItem) => {
    // PriceCalculate(1, data);
    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    ValidationMessage("New item has been added", "success");
    return formData;
  };

  var ValidationMessage = (msg, status) => {
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  };

  // Submit Quotation
  const OnSubmitData = (data) => {
    console.log("new", data);
    setDisablebtn(true);
    if (data.billTo.length === 0) {
      return ValidationMessage("Please select company", "error");
    } else if (data.pr === "" || data.pr === null) {
      return ValidationMessage("Please enter RFQ/PR Number", "error");
    } else if (data.depart === "" || data.depart === null) {
      return ValidationMessage("Please enter department", "error");
    } else if (data.prStatus === "" || data.prStatus === null) {
      return ValidationMessage("Please enter PR Number", "error");
    } else {
      InsertRFQ(data);
      return true;
    }
  };
  const extractData = (text) => {
    let rfqt = text.split("\n");
    rfqData = rfqt[3];

    return setRfqData(text);
  };

  const ContactCall = async (data) => {
    await getCall("persons/company/" + data.id).then((response) => {
      setFormData({ ...formData, PersonList: response });
    });
  };

  const infoCall = async () => {
    Promise.all([
      setFormData({
        ...formData,
        CompanyList: await getCall("company/channel/" + profile[0].id),
      }),
    ]);
  };

  const UpdateItem = (Uitem, extItem, up = 0) => {
    console.log(Uitem);
    if (up === 1) {
      extItem.id = Uitem.id;
      extItem.unit_of_measure = Uitem.unit_of_measure;
      extItem.item_code = Uitem.item_code;
      extItem.item_title = Uitem.item_title;
      extItem.sales_description = Uitem.sales_description;
      extItem.cost = Uitem.cost;
      extItem.item_cost = Uitem.cost;
    } else {
    }

    // PriceCalculate(extItem.item_qty, extItem);

    return extItem;
  };

  const PriceCalculate = (qty = 1, item, type = null, val = null) => {
    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }

    item.item_qty = qty;
    CalulateTotal();
    return item;
  };

  const CalulateTotal = (
    data = formData.SelectedItems,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;
    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;

    if (data.length > 0) {
      total_items_qty = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_qty),
        0
      );
      // total_items_qty = parseInt(
      //   data.reduce(
      //     (prevValue, currentValue) => prevValue + currentValue.item_qty,
      //     0
      //   )
      // );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
      });
    } else {
      let gdtotal = parseInt(formData.SubTotal) + parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(formData.GSTTotal) +
          parseInt(formData.SubTotal) +
          parseInt(Discounts),
      });
    }
    return formData;
  };

  const TableRowNew = (item, index) => {
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5" }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
            style={{ position: "relative" }}
          >
            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              fullwidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(item.item_qty, item, "code", e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      if (newItem) {
                        ValidationMessage(
                          "Item information has been updated",
                          "success"
                        );
                      }
                      UpdateItem(newItem, item, 1);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              multiline
              maxRows={20}
              value={item.item_title}
              fullWidth
              size="small"
              width="100%"
              onChange={(e) => {
                PriceCalculate(
                  item.item_qty,
                  item,
                  "title",
                  e.target.value
                );
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-title-" + index}
              
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5" }}>
            <TextField
              id={"item-mou-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="text"
              size="small"
              value={item.unit_of_measure}
              onChange={(e) => {
                item.unit_of_measure = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            {item.id > 0 ? (
              <>
                <ModelPopup
                  mopen={true}
                  title="Select Vendor"
                  content={<Group fontSize="small" />}
                  childComp={
                    <SearchItemVendorPopup
                      iid={item.id}
                      cid={profile[0].id}
                      idata={item}
                    />
                  }
                ></ModelPopup>
                <ModelPopup
                  mopen={true}
                  title="Edit Item"
                  content={<Edit fontSize="small" />}
                  childComp={
                    <ItemAddComponenet
                      type="edit"
                      edata={item}
                      iid={item.id}
                      addItems={(newItem) => {
                        // console.log(newItem);
                        if (newItem) {
                          ValidationMessage(
                            "Item information has been updated and row has been updated",
                            "success"
                          );
                        }
                        // UpdateItem(newItem, item);
                      }}
                    />
                  }
                />
              </>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px" }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  useEffect(() => {
    LocalUpdate();
    infoCall();

    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to
      // the console.
      profile = JSON.parse(localStorage.getItem("channelDetail"));
    });
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Add New RFQ
            </Typography>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <SubNavBar />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Paper sx={{ width: "30%", py: 2 }}>
            <Grid fluid sx={{ p: 2, pt: 0 }}>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <InputLabel sx={{ mx: 0, px: 0, pb: 6 }}>PR Status</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={formData.prStatus}
                  onChange={(e) => {
                    setFormData({ ...formData, prStatus: e.target.value });
                    return formData;
                  }}
                  label="PR Status"
                  autoWidth
                  fullWidth
                  sx={{ textAlign: "left" }}
                  size="small"
                >
                  <MenuItem sx={{ textAlign: "left" }} value="Active">
                    Active
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Expired">
                    Expired
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="In Progress">
                    In Progress
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Closed">
                    Closed
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Hold">
                    Hold
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Dropped">
                    Dropped
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <TextField
                  id="pr_number"
                  name="pr_number"
                  label="PR/RFQ Number"
                  value={formData.pr}
                  onChange={(e) =>
                    setFormData({ ...formData, pr: e.target.value })
                  }
                  type="text"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <CompanySelection
                  data={formData}
                  selectionOnly={true}
                  fnCall={(e) => (e.id ? ContactCall(e) : "")}
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <SelectPerson
                  data={formData}
                  dataList={formData.PersonList}
                  labelTo="PR Requested By:"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  id="department"
                  name="depart"
                  label="Department"
                  value={formData.depart}
                  onChange={(e) =>
                    setFormData({ ...formData, depart: e.target.value })
                  }
                  type="text"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  fullWidth
                  id="submit_date"
                  label="Submit Date"
                  type="date"
                  defaultValue={formData.submitDate}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, submitDate: e.target.value });
                    return formData;
                  }}
                />{" "}
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  fullWidth
                  id="receive_date"
                  label="PR Received on"
                  type="date"
                  defaultValue={formData.recevieDate}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, recevieDate: e.target.value });
                    return formData;
                  }}
                />{" "}
              </FormControl>
            </Grid>
            <Divider />
            <Button
              sx={{ textAlign: "right", mt: 1, mr: 1 }}
              variant="contained"
              color="success"
              endIcon={<Send />}
              onClick={() => OnSubmitData(formData)}
            >
              Save RFQ
            </Button>
            <Button
              onClick={() => navigate(-1)}
              sx={{ textAlign: "right", mt: 1 }}
              variant="outlined"
              endIcon={<ArrowBack />}
            >
              Back
            </Button>
          </Paper>
          <Paper sx={{ width: "65%", py: 2 }}>
            <Grid fluid sx={{ p: 2 }}>
              <Typography variant="h5" textAlign="left"></Typography>
            </Grid>
            <Divider />

            <Grid fluid sx={{ p: 2 }}>
              {formData.SelectedItems && (
                <Table
                  sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                  size="small"
                  aria-label="a dense table"
                  padding="0"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"50px"}
                      >
                        Sno.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"200px"}
                        align="left"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"500px"}
                      >
                        Product Description
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Qty.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        MOU.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px" }}
                        width={50}
                        colSpan={2}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.SelectedItems.map((item, index) => {
                      return TableRowNew(item, index);
                    })}
                    <TableRow>
                      <TableCell colSpan={8}>
                        <Button
                          onClick={() => {
                            AddSelected();
                            ValidationMessage(
                              "Item row has been added",
                              "success"
                            );
                          }}
                        >
                          Add New Row
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Paper>
        </Box>
        <Paper sx={{ mx: "2%", py: 1, borderRadius: 5 }}>
          <Grid container spacing={1} sx={{ p: 1, pt: 0, pb: 2 }}>
            <Grid textAlign={"left"} md={7} sx={{ px: 2, py: 4, pb: 0 }}>
              <CompanySelection
                data={formData}
                fnCall={(e) => (e.id ? ContactCall(e) : "")}
              />
            </Grid>
            <Grid md={5} sx={{ px: 2, py: 4, pb: 0 }}>
              <PDFUpload pdfData={(t) => extractData(t)} />
            </Grid>
          </Grid>
          <Divider />

          <Divider />

          <Divider />
        </Paper>
      </Box>
    </>
  );
}
