import React, { useEffect } from "react";
import "./../admin/create.css";
import { Button, Input, Modal, Checkbox, Form } from "semantic-ui-react";
import NotificationMessage from "./NotificationMessage";
import magento from "../api/magento";
import { Grid, TextField } from "@mui/material";
import { postCall } from "../Helper/Helper";

const QuoteIntoDc = ({ data, navigate }) => {
  const [open, setOpen] = React.useState(false);
  const [ConvertinData, setConvertinData] = React.useState({
    Items: data.Items,
    checked: true,
  });
  const [message, setMessage] = React.useState({
    message: "",
    status: "",
  });
  const myData = [];

  const HandleCheckBox = (itemData, array) => {
    itemData.checked = !itemData.checked;
    itemData.checked !== false
      ? myData.splice((i) => i.id !== itemData.id, 1)
      : myData.push(itemData);

    console.log(array);
    return;
  };

  const ConvertIntoDC = async (vdata) => {
    if (vdata.length === 0) {
      setMessage({
        message:
          "Please select at least one item to create new delivery challan",
        status: "error",
      });
    } else {
      setMessage({
        message: "",
        status: "",
      });
      
      await postCall("quotations/convert/dc",{ vdata, data }).then((response)=>{
        navigate("/admin/dc/view/" + response.data.insertId, {
          state: {
              message: "Delivery Challen has been sucessfully created against Quotation #" +data.Data[0].quote_number,
            }
          })
        })
      
      // navigate("/admin/dashboard");
    }
  };

  useEffect(()=>{
    console.log('quotedc', data);
  },[])

  return (
    <>
      <Modal
        // size="fullscreen"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        centered={false}
        trigger={
          <Button align="right" className="ui primary tiny" color="black">
            DC Convert
          </Button>
        }
      >
        <Modal.Header>
          Converting Quote# {data.Data[0].quote_number} Into New DC#{" "}
          {data.channel[0].dc_series}
        </Modal.Header>

        <Modal.Content>
          <>
          <h4>Additional Information to DC</h4>

              <Grid container spacing={1}>
                <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                  <TextField
                    required
                    fullWidth
                    id="po_number"
                    label="PO Number"
                    type="text"
                    sx={{ background: "#fff" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      console.log(data.Data[0].po_number);
                      data.po_number = e.target.value;
                      return data;
                    }}
                  />
                </Grid>
              </Grid>
            <h4>Please select items to add into Delivery Challan (DC)</h4>
            <NotificationMessage
              msg={message.message}
              mstatus={message.status}
            />

            <table className="ui celled table">
              <thead>
                <tr>
                  <th></th>
                  <th>Product</th>
                  <th>Code</th>
                  <th>Qty</th>
                  <th>DC Qty</th>
                  <th>Left</th>
                  <th>Rejected</th>

                  <th>Add Qty</th>
                </tr>
              </thead>
              <tbody>
                {ConvertinData.Items.map((item) => {
                  // let v = checkSku(item.sku);
                  item.checked = ConvertinData.checked;
                  item.addedToDC = item.item_qty - item.item_dc_qty;
                  item.left = item.item_qty - item.item_dc_qty;
                  
                  // console.log(ConvertinData.DCItems);
                  return (
                    <>
                      {item.item_dc_qty !== item.item_qty ? (
                        <tr key={item.id}>
                          <td>
                            <Checkbox
                              // defaultChecked
                              value="1"
                              // onClick={() => {
                              //   myData.length === 0
                              //     ? setBtnActive(true)
                              //     : setBtnActive(false);

                              //   // return myData;
                              // }}
                              onChange={() => {
                                HandleCheckBox(item, myData);
                                // console.log(myData);
                              }}
                            />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_dc_qty}</td>
                          <td data-label="itemQty">
                            {/* {item.item_qty - item.item_dc_qty} */}
                            {item.left}
                          </td>
                          <td data-label="itemQty">{item.item_dc_rejection}</td>

                          <td data-label="DcQty" width="20%">
                            <Input
                              size="13"
                              max={item.left}
                              min="1"
                              defaultValue={item.left}
                              type="number"
                              onChange={(e) => {
                                item.addedToDC = e.target.value;
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr key={item.id} className="disabled ">
                          <td>
                            <Checkbox checked />
                          </td>
                          <td data-label="product">{item.item_title}</td>
                          <td data-label="partno">{item.item_code}</td>
                          <td data-label="itemQty">{item.item_qty}</td>
                          <td data-label="itemQty">{item.item_dc_qty}</td>
                          <td data-label="DcQty" width="20%" colSpan={2}>
                            <strong>
                              All Quantity of Item is under Delivery Challan
                            </strong>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        </Modal.Content>
        <Modal.Actions>
          <Form onSubmit={() => ConvertIntoDC(myData)}>
            <Button
              type="submit"
              content="Please Proceed!"
              labelPosition="right"
              icon="checkmark"
              positive
            />
          </Form>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default QuoteIntoDc;
