import React from "react";
import DetailInfoComponents from "./DetailInfoComponents";
import { Grid } from "@mui/material";

const DetailInfoRowComponents = ({data}) => {

    return (
            <>
                <Grid sx={{ px: 2 }} container spacing={2} fontSize={'9pt'}>
            <Grid textAlign={"left"} md={5} sx={{ p: 2 }} >
              <DetailInfoComponents 
                  exp="To" 
                  head={data.Data[0].quote_to_company} 
                  Edit={true} 
                  EditLink={`/admin/quotation/edit/${data.Data[0].id}` } 
                  EditData={{data}}
                  InfoData={[
                          {  
                            title: 'Atten To',
                            infodata: `${data.Data[0].atten_name} (${data.Data[0].department})` 
                          },
                          {  
                            title: 'Address',
                            infodata: data.Data[0].quote_to
                          },
                          {  
                            title: 'STN',
                            infodata: data.Data[0].quote_to_stn
                          },
                          {  
                            title: 'NTN',
                            infodata: data.Data[0].quote_to_ntn
                          },
                          {  
                            title: 'Phone',
                            infodata: data.Data[0].quote_to_phone
                          },
                    ]}
                  />
            </Grid>
            <Grid textAlign={"left"} md={4} sx={{ p: 2 }}>
                <DetailInfoComponents 
                  exp="From" 
                  head={data.channel[0].channel} 
                  Edit={false} 
                  editLink={`/admin/quotation/edit/${data.Data[0].id}` } 
                  editData={data}
                  InfoData={[
                          {  
                            title: 'Address',
                            infodata: data.channel[0].address
                          },
                          {  
                            title: 'STN',
                            infodata: data.channel[0].stn
                          },
                          {  
                            title: 'NTN',
                            infodata: data.channel[0].ntn
                          },
                          {  
                            title: 'Phone',
                            infodata: data.channel[0].phone
                          },
                    ]}
                  />
            </Grid>
            <Grid textAlign={"left"} md={3} sx={{ p: 2 }}>
            <DetailInfoComponents 
                  exp="Generated By" 
                  head={data.channel[0].channel} 
                  InfoData={[
                          {  
                            title: 'Name',
                            infodata: data.Data[0].name
                          }, 
                          {  
                            title: 'Role',
                            infodata: data.Data[0].role
                          },
                          {  
                            title: 'Phone',
                            infodata: data.Data[0].phone
                          },
                    ]}
                  />
            </Grid>
          </Grid>
            </>
        );
}

export default DetailInfoRowComponents;