import React, { useEffect, useState } from "react";
import "./../create.css";
import {
  AppBar,
  Box,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SubNavBar from "../../components/SubNavBar";
import { getCall, profile } from "../../Helper/Helper";

export default function Rfqs({ navigate, props }) {
  const [Rfq, setRfq] = useState([]);
  const [FRow, setFRow] = useState({});
  const [Selected, setSelected] = useState([]);
   

  const CallRfQ = async () => {
    await getCall('rfq/channel/' + profile[0].id).then((response) => {
      console.log(response);
      setRfq(response);
      setFRow(response);
    }
    )
  };

  useEffect(() => {
    CallRfQ();
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Company RfQ/PR
            </Typography>
            <Link
          // target="_blank"
                to={`/rfq/inquriy/pdf/bulk`}
                state={Selected}
          className="ui secondary button tiny"
        > Print Inquriy
        </Link>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
        >
          <Grid item xs={2} sm={4} md={12} textAlign="left"></Grid>
        </Grid>

        <DataGrid
          onSelectionModelChange={setSelected}
          gridRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          rowHeight={30}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            created_by: false,
            id: false,
            // grand_total_gst: false,
          }}
          sx={{ mx: 2, mb: 2, p: 1, background: "#ffffff", fontSize: "10px" }}
          density="standard"
          components={{ Toolbar: GridToolbar }}
          checkboxSelection
          
          columns={[
            { field: "id", hideable: true, headerName: "S#", width: 20 },
            {
              field: "pr",
              hideable: true,
              headerName: "RFQ/PR",
              width: '100',
              renderCell: (params) => <Link to={`/admin/rfq/view/${params.row.id}`}>{params.row.pr}</Link>
            },
            {
              field: "status",
              hideable: true,
              headerName: "PR Status",
              width: 80
            },
            {
              field: "receive_date",
              hideable: true,
              headerName: "PR Received",
              width: 80,
              valueGetter: ({ value }) =>
                value && moment(value).format("YYYY-MM-DD"),
            },
            {
              field: "submit_date",
              hideable: true,
              headerName: "Submit Date",
              width: 80,
              valueGetter: ({ value }) =>
                value && moment(value).format("YYYY-MM-DD"),
            },
            {
              field: "company_id",
              hideable: true,
              headerName: "Company",
              width: 200,
              renderCell: (params) => <Link target="_blank" to={`/admin/company/view/${params.row.company_id}`}>{params.row.company}</Link>,
            },
            {
              field: "requested_by",
              hideable: true,
              headerName: "Requested By",
              width: 100,
              // renderCell: (params) => params.row.name
            },
            {
              field: "depart",
              hideable: true,
              headerName: "Department",
              width: 100,
            },

            {
              field: "actions",
              type: "actions",
              getActions: (GridRowParams) => [
                <Link to={`/admin/rfq/view/${GridRowParams.id}`}>
                  View
                </Link>,
                <Link to={`/admin/rfq/edit/${GridRowParams.id}`}
                  state={GridRowParams.row}
                >
                Edit
              </Link>,
              ],
            },
          ]}
          rows={Rfq}
          pageSize="50"
          getRowClassName={(params) => {
            if (params.row.status === "Expired") {
              return  'pr_expired'
            }else if (params.row.status === "Hold") {
              return  'pr_hold'
            }
            if (params.row.status === "Closed") {
              return  'pr_closed'
            }
            if (params.row.status === "In Progress") {
              return 'pr_inprogress';
            }
            if (params.row.status === "Active") {
              return  'pr_active'
            }
            if (params.row.status === "Pending") {
              return  'pr_pending'
            }
            if (params.row.status === "Dropped") {
              return  'pr_dropped'
            }
          }}
        />
 <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/rfq/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
