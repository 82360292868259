import React, { useEffect, useState } from "react";
import '../admin/create.css'
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import { Save } from "@mui/icons-material";
export default function DateRangeComponent({ data, update,onBtnSubmit }) {
    const [disableFld, setDisableFld] = useState(false);

  useEffect(() => {
    
  }, []);

  return (
    <>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ px: 2 }}
      >
        <Grid item md={16} textAlign="left">
          <Box>
          <Paper className="p1">
          <Select
                labelId="status"
                id="status"
                
                onChange={(e) => {
                    if (e.target.value === 'custom') {
                        setDisableFld(false);
                    } else {
                        setDisableFld(true);
                        return update(e.target.value, 'select')
                    }
                    //  console.log(now);
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", p: 0, mx: 0, my: 0 }}
                
                              >
                <MenuItem sx={{ textAlign: "left" }} value="0" selected>
                  All Companies
                </MenuItem>
               
              </Select>
              </Paper>
          </Box>
        </Grid>
          <Grid item md={3} textAlign="left">
            <Box>
              <Paper className="p1">
              <FormControl sx={{ minWidth: '100%' }}>
              <Select
                labelId="status"
                id="status"
                
                onChange={(e) => {
                    if (e.target.value === 'custom') {
                        setDisableFld(false);
                    } else {
                        setDisableFld(true);
                        return update(e.target.value, 'select')
                    }
                    //  console.log(now);
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", p: 0, mx: 0, my: 0 }}
                
                              >
                <MenuItem sx={{ textAlign: "left" }} value="custom">
                  Custom Range
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="week">
                  This Week
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="month" selected>
                  This Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="lmonth">
                  Last Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="l3month">
                  Last 3 Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="year">
                  This Year
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="lyear">
                  Last Year
                </MenuItem>
              </Select>
            </FormControl>
              </Paper>
              </Box>
          </Grid>
          <Grid item md={3} textAlign="left">
          <Box>
              <Paper className="p1">
              <TextField
                fullWidth
                id="from"
                label="From"
                disabled={disableFld}
                type="date"
                sx={{ background: "#fff" }}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(data.StartDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                    console.log(e.target.value);
                    //    setPeriod({...period, StartDate: e.target.value})
                    return update(e.target.value, 'from');
                }}
              />
              </Paper>
              </Box>
          </Grid>
          <Grid item md={3} textAlign="left">
          <Box>
              <Paper className="p1">
              <TextField
                fullWidth
                id="to"
                label="To"
                type="date"
                disabled={disableFld}
                sx={{ background: "#fff" }}
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                InputLabelProps={{
                  shrink: true,
                }}
                value={moment(data.EndDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                console.log(e.target.value);
                return  update(e.target.value, 'to');
                //   setPeriod({...period, EndDate: e.target.value})
                }}
              />
              </Paper>
              </Box>
              </Grid>
              <Grid item md={3} textAlign="left">
          <Box>
              <Paper className="p2">
                          <Button type="submit" variant="contained" endIcon={<Save />} onClick={onBtnSubmit} size="medium">Generate Report</Button>
              </Paper>
              </Box>
          </Grid>
        </Grid>

    </>
  );
}
