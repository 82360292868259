import React, { useState, useEffect } from "react";
import "./../create.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  Snackbar,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
  FormControl,
  InputLabel,
  Icon,
} from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import { ArrowBack, Edit, FileCopyRounded, Send, Update } from "@mui/icons-material";
import {
  LocalUpdate,
  postCall,
  getCall,
  profile,
  taxes,
  today,
  usd,
  FormattingNumber,
  ActiveUser,
} from "../../Helper/Helper";
import { Link, useParams } from "react-router-dom";
import ModelPopup from "../../components/ModelPopup";
import SubNavBar from "../../components/SubNavBar";
import SelectPerson from "../../components/Select/SelectPerson";

import PDFUpload from "../../components/PDFUpload";
import RFQintoQuote from "../../components/RFQintoQuote";
import moment from "moment";
import ItemAddComponenet from "../../components/ItemAddComponenet";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };

export default function ViewRFQ({ navigate }) {
  const [message, setmessage] = useState("");
  const { rId } = useParams();
  const [GlobalGST, setGlobalGST] = useState(18);
  const [disablebtn, setDisablebtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [rfqData, setRfqData] = useState(true);
  const [formData, setFormData] = useState({
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    PersonList: [],
    DeletedItems: [],
    Channel: {},
    prStatus: "Active",
    item_count_total: 0,
    total_items_qty: 0,
    items_left: 0,
    channel_id: profile[0].id,
    created_by: ActiveUser[0].username,
    user_id: ActiveUser[0].id,
    role: ActiveUser[0].role,
    username: ActiveUser[0].username,
    name: ActiveUser[0].name,
    atten_to: 0,
    pr: "",
    remarks: "",
    CostTotal: 0,
    SubTotal: 0,
    GrandTotal: 0,
    GrandTotalGST: 0,
    GSTTotal: 0, 
    depart: "",
    submitDate: today,
    recevieDate: today,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  // ** Calculate Item Row Total */
  const PriceCalculate = (
    qty = 1,
    item,
    gst = GlobalGST,
    type = null,
    val = null
  ) => {
    // item.item_cost = item.cost;
    var CostTotal = 1 * item.item_cost;
    var saleTaxTotal = parseInt((gst / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((gst / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;
    
    item.item_title = item.item_title;
    item.item_code = item.item_code;
    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    item.CostTotal = CostTotal;
    item.rowTotal = rowTotal;
    item.saleTaxTotal = saleTaxTotal;
    item.subTotalWithoutTax = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  // ** Calculate Total */
  const CalulateTotal = (
    data = formData.Items,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;
    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;


    if (data.length > 0) {
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );

      total_items_qty = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_qty),
        0
      );
      // total_items_qty = parseInt(
      //   data.reduce(
      //     (prevValue, currentValue) => prevValue + currentValue.item_qty,
      //     0
      //   )
      // );

      setFormData({
        ...formData,
        item_count_total: formData.Items.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    } else {
      let gdtotal =
        parseInt(formData.SubTotal) +
        parseInt(Additionalcharges) -
        parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(formData.GSTTotal) +
          parseInt(formData.SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    }
    return formData;
  };

  const RfqItemUpdate = async (d,c) => {
    d.CTOTAL = c;
    await postCall('/rfq/item/' + d.id, d).then(response => {
      console.log(response);
      if (response != "Done") {
        ValidationMessage("Item has been Updated " + d.item_code , "success");
      } else {
        ValidationMessage("RFQ Already exsist", "error");
        
      }
    })
  }

  const  UpdateItem = (Uitem, extItem) => {
    // console.log(Uitem.item_title);
    extItem.item_id = Uitem.id;
    extItem.unit_of_measure = Uitem.unit_of_measure;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.price = Uitem.price;
    extItem.item_cost = Uitem.cost;
    extItem.item_price = Uitem.price;
    // extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    PriceCalculate(extItem.item_qty, extItem);
    RfqItemUpdate(extItem)
    return extItem;
  };

  var ValidationMessage = (msg, status) => {
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  };

  const generateRow = () => {
    if (formData.Items) {
     return  formData.Items.map((i, index) => {
      return (
        <TableRow>
        <TableCell
          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          align="center"
          width={"50px"}
        >
          {index + 1}
        </TableCell>
        <TableCell
          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          width={"200px"}
          align="left"
        >
          {i.item_code}
        </TableCell>
        <TableCell
          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          width={"500px"}
        >
          {i.item_title}
        </TableCell>
        <TableCell
          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          align="center"
          width={"100px"}
        >
          {i.item_qty + " " + i.unit_of_measure}
        </TableCell>
        <TableCell
          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
          align="center"
          width={"100px"}
        >
          {i.item_cost}.00
        </TableCell>
        <TableCell
          sx={{ p: "2px" }}
          width={50}
          colSpan={2}
          >{i.item_cost * i.item_qty}.00</TableCell>
          <TableCell>
          <ModelPopup
                  mopen={true}
                  title="Edit Item"
                  content={<Edit fontSize="small" />}
                  childComp={
                    <ItemAddComponenet
                      type="edit"
                      edata={i}
                      iid={i.item_id}
                      addItems={(newItem) => {
                        // console.log(newItem);
                        if (newItem) {
                          ValidationMessage("Item information has been updated and row has been updated", 'success' )
                        }
                        UpdateItem(newItem, i);
                        
                      }}
                    />
                  }
                />
          </TableCell>
      </TableRow>)  
    })
    } else {
      
    }
    
  } 


  const DataCall = async () => {
    await getCall("rfq/" + rId).then((response) => {
      console.log("nEW", response);
      setFormData(response);
      
      return formData;
    });
  };

  useEffect(() => {
    DataCall();
    
  }, []);

  return (
    <>
      {formData.Data && (
      
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              PR/RFQ #{formData.Data.pr}
              </Typography>
              <RFQintoQuote data={formData} navigate={navigate} />
              <Link
          target="_blank"
                to={`/rfq/inquriy/pdf/${formData.Data.id}`}
                state={formData}
          className="ui secondary button tiny"
        > Print Inquriy
        </Link>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <SubNavBar />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Paper sx={{ width: "30%", py: 2 }}>
            <Grid fluid sx={{ p: 2, pt: 0 }}>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <InputLabel sx={{ mx: 0, px: 0, pb: 6 }}>PR Status</InputLabel>
                <Select
                  disabled
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={formData.Data.status}
                  onChange={(e) => {
                    setFormData({ ...formData, prStatus: e.target.value });
                    return formData;
                  }}
                  label="PR Status"
                  autoWidth
                  fullWidth
                  sx={{ textAlign: "left" }}
                  size="small"
                >
                  <MenuItem sx={{ textAlign: "left" }} value="Active">
                    Active
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Expired">
                    Expired
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="In Progress">
                    In Progress
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Closed">
                    Closed
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Hold">
                    Hold
                  </MenuItem>
                  <MenuItem sx={{ textAlign: "left" }} value="Dropped">
                    Dropped
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <TextField
                  disabled
                  id="pr_number"
                  name="pr_number"
                  label="PR/RFQ Number"
                  value={formData.Data.pr}
                  type="text"
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <TextField
                  id="company"
                  name="company"
                  label="Company"
                  value={formData.Data.company}
                  disabled
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, ml: "1%", width: "98%" }}
              >
                <TextField
                  id="department"
                  name="depart"
                  label="Department"
                  value={formData.Data.depart}
                  type="text"
                  disabled
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  id="department"
                  name="depart"
                  label="Requested By"
                  value={formData.Data.requested_by}
                  type="text"
                  disabled
                />
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  disabled
                  fullWidth
                  id="submit_date"
                  label="Submit Date"
                  type="date"
                  value={moment(formData.Data.submit_date).format("YYYY-MM-DD")}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  
                />{" "}
              </FormControl>
              <FormControl
                textAlign="left"
                sx={{ mb: 2, mx: "2%", width: "96%" }}
              >
                <TextField
                  disabled
                  fullWidth
                  id="receive_date"
                  label="PR Received on"
                    type="date"
                  value={moment(formData.Data.receive_date).format("YYYY-MM-DD")}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />{" "}
              </FormControl>
            </Grid>
            <Divider />
            {/* <Button
              sx={{ textAlign: "right", mt: 1, mr: 1 }}
              variant="contained"
              color="success"
              endIcon={<Send />}
              onClick={() => OnSubmitData(formData)}
            >
              Update RFQ
            </Button>
            <Button
              onClick={() => navigate(-1)}
              sx={{ textAlign: "right", mt: 1 }}
              variant="outlined"
              endIcon={<ArrowBack />}
            >
              Back
            </Button> */}
          </Paper>
          <Paper sx={{ width: "65%", py: 2 }}>
            <Grid fluid sx={{ p: 2 }}>
            
                <Table
                  sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                  size="small"
                  aria-label="a dense table"
                  padding="0"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"50px"}
                      >
                        Sno.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"200px"}
                        align="left"
                      >
                        Item Code
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        width={"500px"}
                      >
                        Product Description
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Qty.
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                        align="center"
                        width={"100px"}
                      >
                        Cost
                      </TableCell>
                      <TableCell
                        sx={{ p: "2px" }}
                        width={50}
                        colSpan={2}
                      >C.Total</TableCell>
                      <TableCell>
                        
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                     generateRow()
                    }
                    
                  </TableBody>
                </Table>
              
            
            </Grid>
            <Divider />

            <Grid fluid sx={{ p: 2 }}>
           
                  <Grid md={6} sx={{ py: 1 }}>
                  {/* <Divider /> */}
                  <Button
              sx={{ textAlign: "right", mt: 1, mr: 1 }}
              variant="contained"
                    color="primary"
                    
                    onLoad={() => {
                      CalulateTotal()
                    }}
              endIcon={<Update />}
                    onClick={() => {
                      CalulateTotal();
              }}
            >
              Calculate RFQ Cost 
            </Button>
                <Typography textAlign={"Right"} variant="h6">
                  Cost Total : {formData.Items.length > 0 ? FormattingNumber(formData.CostTotal) : 0}
                 </Typography>
                  
                  </Grid>
                
            </Grid>
          </Paper>
        </Box>
        <Paper sx={{ mx: "2%", py: 1, borderRadius: 5 }}>
          <Grid container spacing={1} sx={{ p: 1, pt: 0, pb: 2 }}>
            <Grid textAlign={"left"} md={7} sx={{ px: 2, py: 4, pb: 0 }}></Grid>
            <Grid md={5} sx={{ px: 2, py: 4, pb: 0 }}></Grid>
          </Grid>
          <Divider />

          <Divider />

          <Divider />
        </Paper>
      </Box>
      )}
    </>
  );
}
