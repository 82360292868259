import React, { useState, useEffect } from "react";
import "./../create.css";
import styles from "./stylesQuote.css";
import {
  AppBar,
  Divider,
  Paper,
  Toolbar,
  Snackbar,
  InputAdornment,
  FormControl,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Input,
  InputLabel
} from "@mui/material";



import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";

import CompanySelection from "../../components/CompanySelection";
import MuiAlert from "@mui/material/Alert";
import {
  Delete,
  Edit,
  Group,
} from "@mui/icons-material";
import { LocalUpdate, postCall, getCall, profile, taxes,today, usd, FormattingNumber } from "../../Helper/Helper";
import PreviewPopUp from "../../components/PreviewPopUp";
import { Link } from "react-router-dom";
import ModelPopup from "../../components/ModelPopup";
import SubNavBar from "../../components/SubNavBar";
import SelectPerson from "../../components/Select/SelectPerson";
import SearchResultPopup from "../../components/SearchResultPopup";
import ItemAddComponenet from "../../components/ItemAddComponenet";
import CurrencyConvertor from "../../components/CurrencyConvertor";
import SearchItemVendorPopup from "../../components/SearchItemVendorPopup";

const Alert =
  React.forwardRef <
  HTMLDivElement >
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  };



export default function AddQuotation({ navigate }) {
  const ActiveUser = JSON.parse(localStorage.getItem("user"));
  const [GlobalGST, setGlobalGST] = useState(18);
  const [message, setmessage] = useState("");
  const [disablebtn, setDisablebtn] = useState(true);
  const [open, setOpen] = useState(false);

  const EmptyItem = {
    item_title: "",
    item_code: "",
    status: 1,
    item_qty: 1,
    qty: 1,
    price: 0,
    cost: 0,
    item_cost: 0,
    weight: 0,
    currency: "Rs.",
    unit_of_measure: "",
    image: [],
    imageName: "",
    sku: "",
    purchase_description: "",
    sales_description: "",
    part_no: "",
    item_types: "",
    location: "",
    deliveryStatus: "",
    channel_id: profile[0].id,
    id: 0,
  };
  const [formData, setFormData] = useState({
    ListofItems: [{ key: 1, label: `test`, value: 1 }],
    CompanyList: [],
    PersonList:[],
    DeletedItems: [],
    Channel: {},
    SearchResult: ["No Item Found"],
    SelectedItems: [EmptyItem],
    SubTotal: 0,
    GrandTotal: 0,
    GrandTotalGST: 0,
    CostTotal: 0,
    GSTTotal: 0,
    gstinpercentage: GlobalGST,
    addtionalCharges: 0,
    discount: 0,
    item_count_total: 0,
    total_items_qty: 0,
    items_left: 0,
    created_by: ActiveUser[0].username,
    user_id: ActiveUser[0].id,
    role: ActiveUser[0].role,
    username: ActiveUser[0].username,
    name: ActiveUser[0].name,
    atten_to: 0,
    ref_number: "",
    terms:
      usd &&
      "- Today USD Rates:" +
        usd.toFixed(2) +
        "\n- Kindly allow partial payment on partial delivery. \n" +
        "- Prices are valid for 7 days.",
    remarks: "",
    QuoteDate: today,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // ** Calculate Item Row Total */
  const PriceCalculate = (
    qty = 1,
    item,
    gst = GlobalGST,
    type = null,
    val = null
  ) => {
    item.item_cost = item.cost;
    var saleTaxTotal = parseInt((gst / 100) * item.item_price * 1);
    var subTotalWithoutTax = 1 * item.item_price;
    var rowTotal = saleTaxTotal + subTotalWithoutTax;
    var CostTotal = 1 * item.item_cost;
    subTotalWithoutTax = qty * item.item_price;
    saleTaxTotal = parseInt((gst / 100) * item.item_price * qty);
    rowTotal = subTotalWithoutTax + saleTaxTotal;
    if (type === "code") {
      item.item_code = val;
    }
    if (type === "title") {
      item.item_title = val;
    }

    CostTotal = qty * item.item_cost;
    item.item_qty = qty;
    item.price = item.item_price;
    item.item_cost_total = CostTotal;
    item.item_row_total_gst = rowTotal;
    item.item_gst_total = saleTaxTotal;
    item.item_row_total = subTotalWithoutTax;
    item.CostTotal = CostTotal;
    item.rowTotal = rowTotal;
    item.saleTaxTotal = saleTaxTotal;
    item.subTotalWithoutTax = subTotalWithoutTax;
    CalulateTotal();
    return item;
  };

  // ** Calculate Total */
  const CalulateTotal = (
    data = formData.SelectedItems,
    Additionalcharges = formData.addtionalCharges,
    Discounts = formData.discount
  ) => {
    let SubTotal = 0;
    let CostTotal = 0;
    let GSTTotal = 0;
    let total_items_qty = 0;

    if (data.length > 0) {
      SubTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_row_total),
        0
      );

      CostTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_cost_total),
        0
      );
      GSTTotal = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_gst_total),
        0
      );

      total_items_qty = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.item_qty),
        0
      );
      // total_items_qty = parseInt(
      //   data.reduce(
      //     (prevValue, currentValue) => prevValue + currentValue.item_qty,
      //     0
      //   )
      // );

      setFormData({
        ...formData,
        item_count_total: formData.SelectedItems.length,
        GSTTotal: parseInt(GSTTotal),
        SubTotal: parseInt(SubTotal),
        GrandTotal:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        GrandTotalGST:
          parseInt(SubTotal) +
          parseInt(Additionalcharges) +
          parseInt(GSTTotal) -
          parseInt(Discounts),
        CostTotal: CostTotal,
        total_items_qty: total_items_qty,
        addtionalCharges: parseInt(Additionalcharges),
        discount: parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    } else {
      let gdtotal =
        parseInt(formData.SubTotal) +
        parseInt(Additionalcharges) -
        parseInt(Discounts);
      setFormData({
        ...formData,
        discount: parseInt(Discounts),

        addtionalCharges: parseInt(Additionalcharges),
        GrandTotal: parseInt(gdtotal),
        GrandTotalGST:
          parseInt(formData.GSTTotal) +
          parseInt(formData.SubTotal) +
          parseInt(Additionalcharges) -
          parseInt(Discounts),
        gstinpercentage: GlobalGST,
      });
    }
    return formData;
  };

  // Delete Item
  const DeleteItem = (data, dindex) => {
    ValidationMessage( "Item row deleted","error")
    setFormData({
      ...formData,
      DeletedItems: [
        ...formData.DeletedItems,
        formData.SelectedItems.filter((item, index) => {
          if (index === dindex) {
            return item;
          }
        }),
      ],
      SelectedItems: formData.SelectedItems.filter((item, index) => {
        return index !== dindex;
      }),
    });
  };

  // Add Selected Item From Popup search menu
  const AddSelected = (data = EmptyItem) => {
    PriceCalculate(1, data);
    setFormData({
      ...formData,
      SelectedItems: [...formData.SelectedItems, data],
    });
    ValidationMessage("New item has been added",'success')
    return formData;
  };

  // Insert Quotation
  const InsertQuotation = async (data) => {
    await postCall("quotations/create", data).then((response)=>{
      navigate("/admin/quotation/view/" + response.data.insertId, {
        state: {
            message: "Quotation has been sucessfully created",
          },
      })
    }).catch(function(err){
      ValidationMessage(err.message,"error")
    })
  };

  var ValidationMessage = (msg, status)=>{
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  }

  // Submit Quotation
  const OnSubmitData = (data, profile) => {
    setDisablebtn(true);
    CalulateTotal();
    if (data.billTo.length === 0) {
      return ValidationMessage("Please select company","error" );
    } else if (data.SelectedItems.length === 0) {
      return ValidationMessage("Please add items in quotataion to proceed further","error" );
    } else {
      CalulateTotal(data);
      InsertQuotation({ data, profile });
      return true;
    }
  };
  const UpdateItem = (Uitem, extItem) => {
    console.log(Uitem.item_title);
    extItem.id = Uitem.id;
    extItem.unit_of_measure = Uitem.unit_of_measure;
    extItem.item_code = Uitem.item_code;
    extItem.item_title = Uitem.item_title;
    extItem.price = Uitem.price;
    extItem.item_price = Uitem.price;
    extItem.price = Uitem.price;
    extItem.cost = Uitem.cost;
    extItem.sales_description = Uitem.sales_description;
    PriceCalculate(extItem.item_qty, extItem);

    return extItem;
  };
  const TableRowNew = (item, index) => {
    return (
      <FormControl fullWidth style={{ fontSize: "8px", display: "contents" }}>
        <TableRow>
          <TableCell
            sx={{ padding: "0 2px 0 0", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}
            align="center"
          >
            {index + 1}.
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}
            style={{ position: "relative" }}
          >
            <Input
              id={"item-code-" + index}
              sx={{ border: "none", "& fieldset": { border: "none" } }}
              value={item.item_code}
              defaultValue={item.item_code}
              fullwidth
              size="small"
              width="100%"
              onChange={(e) =>
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "code",
                  e.target.value
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  <SearchResultPopup
                    id={profile[0].id}
                    term={item.item_code}
                    index={index}
                    updateItem={(newItem) => {
                      if (newItem) {
                        ValidationMessage("Item information has been updated", 'success')
                      }
                      UpdateItem(newItem, item);
                    }}
                  />
                </InputAdornment>
              }
            />
          </TableCell>

          <TableCell sx={{ p: "1px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                px: 0,
                border: "none",
                "& input": { px: "4px", fontSize: "0.575rem" },
                "& fieldset": { border: "none" },
              }}
              multiline
              value={item.item_title}
              fullWidth
              size="small"  
              width="100%"
              onChange={(e) => {
                PriceCalculate(
                  item.item_qty,
                  item,
                  GlobalGST,
                  "title",
                  e.target.value
                );
              }}
            />
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}>
            <TextField
              id={"item-title-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="number"
              size="small"
              value={item.item_qty}
              onChange={(e) => {
                PriceCalculate(e.target.value, item);
                return formData;
              }}
            />
           
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}>
            <TextField
              id={"item-mou-" + index}
              sx={{
                p: "0",
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              width="100%"
              fullWidth
              type="text"
              size="small"
              value={item.unit_of_measure}
              onChange={(e) => {
                item.unit_of_measure = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
            
          </TableCell>
          <TableCell sx={{ p: "0px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}>
            <TextField
              sx={{
                border: "none",
                "& input": { px: "4px", fontSize: "0.875rem" },
                "& fieldset": { border: "none" },
              }}
              fullwidth
              type="number"
              size="small"
              width="100%"
              value={item.item_price}
              onChange={(e) => {
                item.item_price = e.target.value;
                PriceCalculate(item.item_qty, item);
                return formData;
              }}
            />
            <br />
            <small>{item.item_cost  > 0 ? "Cost:" + item.item_cost : ''}</small>
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}
            align="right"
          >
            {item.item_gst_total && FormattingNumber(item.item_gst_total)}
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}
            align="right"
          >
            {item.item_row_total && FormattingNumber(item.item_row_total_gst)}
            <br/>
            <small>{item.CostTotal > 0 ? "Cost:" + item.CostTotal : '' }</small>
          </TableCell>
          <TableCell
            sx={{ p: "2px", borderRight: "1px solid #d5d5d5", verticalAlign:'top' }}
            align="right"
          >
            <FormControl sx={{ minWidth: 100 }}>
              <Select
                labelId="status"
                id="status"
                value={item.deliveryStatus}
                onChange={(e) => {
                  item.deliveryStatus = e.target.value;
                  PriceCalculate(item.item_qty, item);
                  return formData;
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", p: 0, mx: 0, my: 0 }}
                size="small"
              >
                 <MenuItem sx={{ textAlign: "left" }} value="N/A">
                  N/A
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="Ex Stock">
                  Ex Stock
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Week">
                  1 Week
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1-2 Week">
                  1-2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2-3 Weeks">
                  2-3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Weeks">
                  3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3-4 Weeks">
                  3-4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4 Weeks">
                  4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4-5 Weeks">
                  4-5 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Month">
                  1 Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Months">
                  2 Months
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Months">
                  3 Months
                </MenuItem>
              </Select>
            </FormControl>
          </TableCell>

          <TableCell sx={{ px: "5px", verticalAlign:'top' }}>
            {item.id > 0 ? (
              <>
                <ModelPopup
                  mopen={true}
                  title="Select Vendor"
                  content={<Group fontSize="small" />}
                  childComp={
                    <SearchItemVendorPopup
                      iid={item.id}
                      cid={profile[0].id}
                      idata={item}
                    />
                  }
                ></ModelPopup>
                <ModelPopup
                  mopen={true}
                  title="Edit Item"
                  content={<Edit fontSize="small" />}
                  childComp={
                    <ItemAddComponenet
                      type="edit"
                      edata={item}
                      iid={item.id}
                      addItems={(newItem) => {
                        // console.log(newItem);
                        if (newItem) {
                          ValidationMessage("Item information has been updated and row has been updated", 'success' )
                        }
                        UpdateItem(newItem, item);
                      }}
                    />
                  }
                />
              </>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ px: "5px", verticalAlign:'top' }}>
            <Delete
              onClick={(e) => {
                return DeleteItem(item, index);
              }}
              sx={{ color: "#db2828" }}
            />
          </TableCell>
        </TableRow>
      </FormControl>
    );
  };

  const ContactCall = async (data) =>{
    await getCall('persons/company/'+ data.id).then((response)=>{
      setFormData({...formData, PersonList: response})})
  }

  const infoCall = async () =>{
    Promise.all([
      setFormData({ ...formData, CompanyList: await getCall("company/channel/" + profile[0].id)})
    ])

  }
 
  useEffect(() => {
    LocalUpdate()
    infoCall()
    
    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      profile = JSON.parse(localStorage.getItem('channelDetail'))
    });
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              New Quotation #{profile[0].qoute_series}
            </Typography>
          </Toolbar>
        </AppBar>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <MuiAlert
            elevation={6}
            variant="filled"
            sx={{ width: "100%" }}
            onClose={handleClose}
            severity={message.status}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
        <SubNavBar />
        <Paper sx={{ mx: "2%", py: 1, borderRadius: 5 }}>
          <Grid container spacing={1} sx={{p:1, pt:0, pb:2}}>
            <Grid textAlign={"left"} md={7} sx={{ px: 2, py: 4, pb: 0 }}>
                <CompanySelection data={formData} fnCall={(e)=> e.id ? ContactCall(e): ''} />
              </Grid>
              <Grid md={5} sx={{ px: 2, py: 4, pb: 0 }}>
                <SelectPerson data={formData} dataList={formData.PersonList}  />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ p: 1 }} bgcolor={"#f8f8f8"} height="100">
            <Grid container spacing={1}>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="quote_number"
                  label="Quotation #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={profile[0].qoute_series}
                  disabled
                />
                <Link to={"/admin/channel/edit/" + profile[0].id}>Edit</Link>
                <br />
                <br />
                <TextField
                  fullWidth
                  id="ref_number"
                  label="Reference #"
                  type="text"
                  sx={{ background: "#fff" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  marginBottom="0"
                  onChange={(e) => {
                    setFormData({ ...formData, ref_number: e.target.value });
                    return formData;
                  }}
                />
                 <br />
                  <br />
                  <FormControl sx={{ width: "100%" }}>
                  <InputLabel variant="outlined" htmlFor="uncontrolled-native">
                  Default Delivery Status
                    </InputLabel>
                    <Select
                      
                      label="Default Delivery Status"
                      labelId="status"
                      inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                      }}
                id="status"
                value={formData.DefaultDeliveryStatus}
                onChange={(e) => {
                  formData.DefaultDeliveryStatus = e.target.value;
                  formData.SelectedItems.map((item) => {
                    item.deliveryStatus = e.target.value;
                    PriceCalculate(item.item_qty, item);
                    return formData;
                  })
                  
                   return formData;
                  
                }}
                autoWidth
                fullWidth
                sx={{ textAlign: "left", p: 0, mx: 0, my: 0,  background: "#fff", width:"100%" }}
                size="medium"
              >
                 <MenuItem sx={{ textAlign: "left" }} value="N/A">
                  N/A
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="Ex Stock">
                  Ex Stock
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Week">
                  1 Week
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1-2 Week">
                  1-2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Weeks">
                  2 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2-3 Weeks">
                  2-3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Weeks">
                  3 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3-4 Weeks">
                  3-4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4 Weeks">
                  4 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="4-5 Weeks">
                  4-5 Weeks
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="1 Month">
                  1 Month
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="2 Months">
                  2 Months
                </MenuItem>
                <MenuItem sx={{ textAlign: "left" }} value="3 Months">
                  3 Months
                </MenuItem>
              </Select>
            </FormControl>
              </Grid>
              <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="create_at"
                  label="Quotation Issue Date"
                  type="date"
                  defaultValue={formData.QuoteDate}
                  sx={{ background: "#fff" }}
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData({ ...formData, QuoteDate: e.target.value });
                    return formData;
                  }}
                />{" "}
                <br />
                <br />
                <br />
                <p>
                  <strong>USD:</strong>
                  <CurrencyConvertor CurrFrom="usd" />
                </p>
                {/* <Select
                  sx={{ background: "#fff" }}
                  label="Status"
                  value={status}
                  onChange={ChangeHandle}
                  defaultValue={() => {
                    return (
                      <>
                        <MenuItem value="Draft">Draft</MenuItem>
                      </>
                    );
                  }}
                  fullWidth
                  required
                  placeholder="Select Status"
                >
                  <MenuItem value="Draft">Draft</MenuItem>
                </Select> */}
              </Grid>
              {/* <Grid textAlign={"left"} md={3} sx={{ px: 2, py: 4 }}>
             
              </Grid> */}
              <Grid textAlign={"left"} md={6} sx={{ px: 2, py: 4 }}>
                <TextField
                  fullWidth
                  id="terms"
                  label="Terms & Conditions"
                  placeholder="Please enter Terms & Conditions"
                  multiline
                  variant="outlined"
                  sx={{ background: "#fff" }}
                  rows={6}
                  value={formData.terms}
                  onChange={(e) => {
                    setFormData({ ...formData, terms: e.target.value });
                    return formData;
                  }}
                />
                <br />
                <br />
                <TextField
                fullWidth
                id="remarks"
                sx={{ background: "#fff" }}
                label="Remarks"
                placeholder="Please enter remarks"
                multilineInputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                rows={2}
                multiline
                value={formData.remarks}
                onChange={(e) => {
                  setFormData({ ...formData, remarks: e.target.value });
                  return formData;
                }}
              />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Grid container spacing={1}>
            <Grid textAlign={"left"} md={12} sx={{ px: 4, py: 3 }}>
              <Typography variant="h6" color={"#777"} fontSize={20}>
                Details
              </Typography>
              {/* <SearchBar/> */}
            </Grid>
            <Grid textAlign={"right"} md={12} sx={{ px: 4, pb: 4 }}>
              {formData.SelectedItems && (
                <>
                  {/* <FormControl fullWidth="true" style={{fontSize:"8px"}} >               */}
                  <Table
                    sx={{ minWidth: 650, border: "1px solid #d5d5d5" }}
                    size="small"
                    aria-label="a dense table"
                    padding="0"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"50px"}
                        >
                          Sno.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"200px"}
                          align="left"
                        >
                          Item Code
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          width={"500px"}
                        >
                          Product Description
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"100px"}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"100px"}
                        >
                          MOU.
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"150px"}
                        >
                          GST
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px", borderRight: "1px solid #d5d5d5" }}
                          align="center"
                          width={"200px"}
                        >
                          Row Total{" "}
                        </TableCell>
                        <TableCell sx={{ p: "2px" }} width={100}>
                          Status
                        </TableCell>
                        <TableCell
                          sx={{ p: "2px" }}
                          width={50}
                          colSpan={2}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.SelectedItems.map((item, index) => {
                        return TableRowNew(item, index);
                      })}
                      <TableRow>
                        <TableCell colSpan={8}>
                          <Button
                            onClick={() => {
                              AddSelected();
                              ValidationMessage("Item row has been added", 'success');
                            }}
                          >
                            Add New Row
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* </FormControl> */}
                </>
              )}
            </Grid>

            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
            <Grid md={7} sx={{ px: 4, py: 2 }} container>
                <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={5} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.SubTotal)}
                </Typography>
                  </Grid>
                <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Cost Total
                </Typography>
              </Grid>
              <Grid md={5} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.CostTotal)}
                </Typography>
                  </Grid>
                  <Grid md={6} sx={{ py: 1 }}>
                  <Divider />
                  
                <Typography textAlign={"left"} variant="h6">
                  Profit
                </Typography>
                  </Grid>
                  
                  <Grid md={5} sx={{ py: 1 }}>
                  <Divider />
                <Typography textAlign={"right"} variant="h6">
                <small>({Math.round((formData.SubTotal - formData.CostTotal)/formData.CostTotal * 100) }%)</small>  
                      {FormattingNumber(formData.SubTotal - formData.CostTotal)}
                </Typography>
                  </Grid>
              </Grid>
            </Grid>
            <Grid md={5} sx={{ px: 4, py: 2 }} container>
              <Grid textAlign={"left"} md={12} sx={{ pb: 2 }}>
                <TextField
                  fullWidth
                  id="discount"
                  label="Discount"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={0}
                  onChange={(e) => {
                    CalulateTotal(
                      formData,
                      formData.addtionalCharges,
                      e.target.value
                    );
                    return formData;
                  }}
                />
              </Grid>
              <Grid textAlign={"left"} md={12}>
                <TextField
                  fullWidth
                  defaultValue={0}
                  id="additional_charges"
                  label="Additonal Charges"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    CalulateTotal(formData, e.target.value);
                    return formData;
                  }}
                />
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Sub Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.SubTotal)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <FormControl sx={{ minWidth: 300 }}>
                  <InputLabel
                    id="demo-simple-select-autowidth-label"
                    size="small"
                    sx={{ m: 0, p: 0 }}
                  >
                    GST
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={GlobalGST}
                    onChange={(e) => {
                      setGlobalGST(e.target.value);
                      formData.SelectedItems.map((item) =>
                        PriceCalculate(item.item_qty, item, e.target.value)
                      );
                      return GlobalGST;
                    }}
                    autoWidth
                    fullWidth
                    sx={{ textAlign: "left" }}
                    size="small"
                    label="GST"
                  >
                    <MenuItem sx={{ textAlign: "left" }} value="0">
                      No Tax
                    </MenuItem>
                    {taxes.map((item) => {
                      return (
                        <MenuItem
                          sx={{ textAlign: "left" }}
                          value={item.percentage}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <Typography textAlign={"left"} variant="h6">
                Sales Tax (GST)
                </Typography> */}
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h6">
                  {FormattingNumber(formData.GSTTotal)}
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"left"} variant="h6">
                  Grand Total
                </Typography>
              </Grid>
              <Grid md={6} sx={{ py: 1 }}>
                <Typography textAlign={"right"} variant="h5">
                  { FormattingNumber(formData.GrandTotalGST)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid textAlign={"left"} md={7} sx={{ px: 4, py: 2 }}>
              <PreviewPopUp data={[formData, profile[0]]} />
            </Grid>
            <Grid textAlign={"right"} md={5} sx={{ px: 4, py: 2 }}>
              <Button
                variant="contained"
                color="inherit"
                type="submit"
                onClick={() => {
                  CalulateTotal();
                  setDisablebtn(false);
                  console.log(formData);
                  sessionStorage.setItem(
                    "previewdata",
                    JSON.stringify(formData, profile)
                  );
                }}
                sx={{ mx: 3 }}
              >
                Calculate
              </Button>
              <Button
                onClick={() => OnSubmitData(formData, profile[0])}
                type="submit"
                variant="contained"
                color="success"
                size="large"
                disabled={disablebtn}
              >
                Save Quotation
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
