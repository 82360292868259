import React, { useEffect, useState } from "react";
import "./../../create.css";
import {
  AppBar,
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { Add, MenuOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {FormattingNumber, HelperDateCalc, postCall, profile, profileId, today, companyList, LocalUpdate  } from "../../../Helper/Helper";

import SubNavBar from "../../../components/SubNavBar";
import DateRangeComponent from "../../../components/DateRange";
import TotalComp from "../../../components/TotalComp";

export default function ReportsInvoices({ navigate }) {
  const [open, setOpen] = useState(false);
  const [message, setmessage] = useState("");
  const [Reports, setReports] = useState([{ id: 1 }, { id: 2 }]);
  const [rdata, setRdata] = useState({
    gstTotal: 0,
    CountTotal: 0,
    RecordTotal: 0,
    companies:"",
  });
  const [period, setPeriod] = useState({
    StartDate: today,
    EndDate: today,
    channel_id: profileId,
    channel: profile[0],
  });

  const clickHandler = (array, value, v2, v3) => {
    if (array.filter(i => i.company === value? true : false)) {
      // console.log(value, 'Adding for first time');
      array.push({company: value, stn: v2, ntn:v3});
        // setter(newArray)
    } else {
      // console.log('Already in Array')
    }
    const mapFromColors = new Map(
      array.map(c => [c.company, c])
    );
    
    const uniqueColors = [...mapFromColors.values()];
    console.log(uniqueColors)
    return setRdata({...rdata, companies: uniqueColors})
}

  const CalcReportsTotal = (data) => {
    let arr = [];
    if (data.length > 0) {
      // console.log(data.length);
      data.map((item) => {
        clickHandler(arr, item.bill_to_company, item.bill_to_stn, item.bill_to_ntn)
      })
      
      const st = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.subtotal),
        0
      );
      const gstt = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.gst_total),
        0
      );
      const gt = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.grand_total_gst),
        0
      );
      const ct = data.reduce(
        (prevValue, currentValue) =>
          parseInt(prevValue) + parseInt(currentValue.total_items_qty),
        0
      );
      setRdata({
        ...rdata,
        gstTotal: gstt,
        subTotal: st,
        GrandTotal: gt,
        CountTotal: ct,
         RecordTotal:data.length,
       })
    }
    
  }

  const CallReportsInvoices = async (data) => {
    await postCall("/reports/invoices", data).then((response) => {
      setReports(response.data);
      CalcReportsTotal(response.data);
      console.log(Reports, 'newer')
      // return Reports;
    }).catch(function (err) {
      
      ValidationMessage(err.message, "error")
    })
  };

  var ValidationMessage = (msg, status) => {
    handleClickOpen();
    setmessage({
      message: msg,
      status: status,
    });
    return message;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const DateCalc = (n, t) => {
    let d = HelperDateCalc(n, t);
    // console.log('another new', companyList)

    if (d.t === 'to') {
      setPeriod({ ...period, EndDate: d.n})
    } else if (d.t === 'from') {
      setPeriod({...period, StartDate: d.n})
    } else {
      setPeriod({...period, StartDate: d.stDate, EndDate: d.enDate})
    }
  }
  

  useEffect(() => {
    LocalUpdate();
    // console.log('CompanyList',Reports.Company)
  }, []);

  return (
    <>
      <Box bgcolor="#f9f9f9" fluid height="100vh">
        <AppBar
          position="static"
          color="default"
          enableColorOnDark
          sx={{ mb: 3 }}
        >
          <Toolbar sx={{ textAlign: "left" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuOutlined />
            </IconButton>
            <Typography
              variant="h4"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Reports Invoices
            </Typography>
          </Toolbar>
        </AppBar>
        <SubNavBar />
        <DateRangeComponent data={period} update={(n, t) => DateCalc(n, t)} onBtnSubmit={(n) => CallReportsInvoices(period)} />
        <TotalComp data={rdata} records={Reports} />
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{
            px: 2, mt:10
          }}
        >
          <Grid item md={12} textAlign="left">
          <Box sx={{mt:4}}>
              <Paper className="p1">
                <Grid sx={{ display: 'flex', alignContent: "end", alignItems: "end" }} item md={12}>
                <Link
                  to={{ pathname: `/admin/reports/pdf/invoices/` }}
                  state={{ rdata, Reports, period }}
                >
                  Print
                </Link><Button >Download</Button><Button >Excel </Button>
                </Grid>
                <Grid item md={12}>

                
                  <h3>Invoice Reports <small>(From: {period.StartDate} To: {period.EndDate})</small></h3>
                  <table style={{fontSize:'12px'}} width={"100%"} cellSpacing={0} border={1} cellPadding={5} >
                      <th align="center"  width="10%">INV</th>
                      <th align="center" width="5%">Type</th>
                      <th align="center" width="10%">PO</th>
                      <th align="left" width="30%">Company</th>
                      <th align="center"  width="5%" >Count</th>
                      <th align="right"  width="15%">SubTotal</th>
                      <th align="right"  width="10%">GST/SST</th>
                      <th align="right" width="20%">Total</th>
                    <tbody>
                      
                      {Reports.map((i) => {
                        return <>
                          <tr>
                            <td align="center"><a target="_blank" href={"/admin/invoice/view/"+ i.id}>{i.invoice_number_type}</a></td>
                            <td align="center">{i.tax_type}</td>
                            <td align="center">{i.po_number}</td>
                            <td><a target="_blank" href={"/admin/company/view/"+ i.company_id}>{i.bill_to_company}</a></td>
                            <td align="center" >{i.total_items_qty}</td>
                            <td  align="right">{FormattingNumber(i.subtotal)}.00</td>
                            <td  align="right">{FormattingNumber(i.gst_total)}.00</td>
                            <td  align="right" >{FormattingNumber(i.grand_total_gst)}.00</td>
                          </tr>
                        </>
                      })}


                    </tbody>
                  </table>
                  
                </Grid>
              </Paper>
              </Box>
          </Grid>
        </Grid>
        
     
        <div style={{ position: "fixed", bottom: 50, right: 30 }}>
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            style={{ postion: "fixed", top: 0, right: 0 }}
            onClick={() => navigate("admin/quotation/create")}
          >
            <Add font-size="18" />
          </Fab>
        </div>
      </Box>
    </>
  );
}
