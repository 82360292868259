import React, { useState, useEffect } from "react";
import "./../../admin/create.css";
import { Button } from "semantic-ui-react";
import {
  Autocomplete,
  TextField,
  Typography,
  InputAdornment,
 
} from "@mui/material";
import { Box } from "@mui/system";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";
const SelectPerson = ({ data, dataList, labelTo = 'Attention To:', Disable = false }) => { 
  const [nData, setnData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const options = () => {
    return dataList.map((i) => {

      return { key: i.id, label: i.name, value: i.id };
  })
}

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelectChange = (e, sdata) => {
    if (sdata !== null) {
      dataList.map((ie) => {
        return ie.id === sdata.value ? setnData(ie) : "";
      });
      console.log(sdata, data);
    } else {
      setnData([]);
    }

    return data;
  };

  useEffect(() => {
    data.person = nData;
    data.atten_to = nData.id ? nData.id : 0;
   
  }, [nData]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        {/* <Typography
          style={{}}
          variant="h6"
          textAlign="left"
          color={"#777"}
          fontSize={20}
        >
          To:
        </Typography> */}
        <Autocomplete
          disablePortal
          fullWidth
          id="company"
          options={options()}
          sx={{
            width: "90%",
            "& input": { width: "100%" },
            background: "#fff",
            marginRight: "15px",
          }}
          autoHighlight
         
          getOptionSelected={(option, value) => option === value}
          onChange={(e, data) => handleSelectChange(e, data)}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              startAdornment={
                <InputAdornment position="start">
                  <Typography>TO:</Typography>
                </InputAdornment>
              }
              variant="outlined"
              {...params}
              label={labelTo}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          loading
        />
    
        <Button ria-describedby={id} size="small" color="success">
          <Link target="_blank" to={"/admin/person/create"} style={{ color: "#000000" }}>
            <Add />
          </Link>
        </Button>
      </div>
    </>
  );
};

export default SelectPerson;
